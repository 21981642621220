



/**
 * Start the animations
 */
export function animateElementsOnPage() {

  // /**
  //  * Get the y position for animate.css
  //  * @returns {number}
  //  */
  //
  // function getYPosition() {
  //   // scrollY
  //   return window.scrollY || document.documentElement.scrollTop;
  // }

  let $windowWidth = document.documentElement.clientWidth;
  let $windowHeight = 0;

  if ($windowWidth >= 992) {
    window.addEventListener('scroll', function () {
      document.querySelectorAll('.animate').forEach(animateElement => {
        let animationType = animateElement.getAttribute('data-animation');
        if (animationType == null) {
          animationType = 'fadeIn';
        }

        let animationDelay = animateElement.getAttribute('data-animation-delay');
        if (animationDelay == null) {
          animationDelay = '0';
        }

        let animationSpeed = animateElement.getAttribute('data-animation-speed');
        if (animationSpeed == null) {
          animationSpeed = '1000';
        }

        let animationOptions = {
          'animation-delay': animationDelay + 'ms',
          '-webkit-animation-delay': animationDelay + 'ms',
          'animation-duration': animationSpeed + 'ms',
          '-webkit-animation-duration': animationSpeed + 'ms',
        };

        let animationOffset = animateElement.getAttribute('data-animation-offset');
        if (animationOffset == null) {
          animationOffset = '90%';
        }

        $windowHeight = document.documentElement.clientHeight;

        // let $scrollTop = getYPosition();
        let $scrollTop = window.scrollY;

        let $elementPosition = animateElement.getBoundingClientRect().top + window.scrollY;

        let triggerPoint = $elementPosition - $windowHeight * (parseFloat(animationOffset) / 100);

        // console.log('id:' + animateElement.id +' View Height: ' + $windowHeight + ' ScrollAbstand oben: ' + $scrollTop + ' triggerPoint ' + triggerPoint + '$elementPosition: ' +$elementPosition );
        // console.log($scrollTop + ' > ' + triggerPoint);

        if ($scrollTop > triggerPoint) {
          // console.log('Animation start for ID ' + animateElement.id  + ' Type:' + animationType);
          animateElement.classList.add('animate__animated');
          animateElement.classList.add('animate__' + animationType);
          animateElement.classList.add('visible');
          animateElement.style.cssText = animationOptions;
        }
      })
    });
  } else {
    document.querySelectorAll(".animate").forEach(animate => {
      animate.classList.add('visible');
    })
  }
}
