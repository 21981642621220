// import {CompanyContainer} from "./CompanyContainer";
//
// export const UserContainer = (user, onClick) => {
//   const html = `
//         <a href="#">${user.getFullName()}</a>
//         <div class="user-details hide">
//             <p>Birthday: ${user.getBirthDay()}</p>
//             <p><img src="" width="100px"></p>
//             ${CompanyContainer(user.companyId)}
//         </div>
//         `;
//   const element = document.createElement('div');
//   element.innerHTML = html;
//   element.querySelector('a').addEventListener('click', onClick);
//   return element;
// };



export function otScrollToTop() {

  const html = `
<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" style="fill: #fff;" viewBox="0 0 384 512"><path d="M35.5 279.9l148-148.4c4.7-4.7 12.3-4.7 17 0l148 148.4c4.7 4.7 4.7 12.3 0 17l-19.6 19.6c-4.8 4.8-12.5 4.7-17.1-.2L218 219.2V468c0 6.6-5.4 12-12 12h-28c-6.6 0-12-5.4-12-12V219.2l-93.7 97.1c-4.7 4.8-12.4 4.9-17.1.2l-19.6-19.6c-4.8-4.7-4.8-12.3-.1-17zM12 84h360c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44v28c0 6.6 5.4 12 12 12z"/></svg>
`;
  console.log('Scroll Top Init');
  let scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('id', 'ot-to-top');
  scrollDiv.setAttribute('class', 'scrollToTopBtn');
  // scrollDiv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" style="fill: #fff;" viewBox="0 0 384 512"><path d="M35.5 279.9l148-148.4c4.7-4.7 12.3-4.7 17 0l148 148.4c4.7 4.7 4.7 12.3 0 17l-19.6 19.6c-4.8 4.8-12.5 4.7-17.1-.2L218 219.2V468c0 6.6-5.4 12-12 12h-28c-6.6 0-12-5.4-12-12V219.2l-93.7 97.1c-4.7 4.8-12.4 4.9-17.1.2l-19.6-19.6c-4.8-4.7-4.8-12.3-.1-17zM12 84h360c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44v28c0 6.6 5.4 12 12 12z"/></svg>';
  scrollDiv.innerHTML = html;
  scrollDiv.addEventListener('click', scrollToTop);
  document.body.appendChild(scrollDiv);
  console.log(scrollDiv);

  document.addEventListener("scroll", handleScroll);


  function handleScroll() {
    let scrollToTopBtn = document.getElementById('ot-to-top');
    let scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let GOLDEN_RATIO = 0.1;

    if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
      //show button
      console.log('Scroll show');
      if (!scrollToTopBtn.classList.contains("showScrollBtn"))
        scrollToTopBtn.classList.add("showScrollBtn")
    } else {
      //hide button
      console.log('scroll hide');
      if (scrollToTopBtn.classList.contains("showScrollBtn"))
        scrollToTopBtn.classList.remove("showScrollBtn")
    }
  }

  /**
   * Scroll to the top of the page
   */
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

}


/**
 * Scroll to top
 *
 *  .scrollToTopBtn {
 *    position: fixed;
 *    bottom: 50px;
 *    right: 50px;
 *    display: block;
 *    padding: 8px 4px;
 *    cursor: pointer;
 *    transform: translateY(100px);
 *    opacity: 0;
 *    background-color: red;
 *    transition: transform 0.5s, opacity 0.3s;
 * }
 *
 *  .showScrollBtn {
 *    transform: translateY(0);
 *    opacity: 1;
 *  }
 *
 */

